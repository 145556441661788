import React, { useState, useEffect, useCallback } from 'react';
import './App.css';  // Import the CSS file for styling

function ResRepo() {
  const [candidates, setCandidates] = useState([]); // Corrected state definition
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);  // New loading state
  const [selectedCandidate, setSelectedCandidate] = useState(null);  // State to track selected candidate for popup
  const [borderColor, setBorderColor] = useState(''); // State to store random border color
  const itemsPerPage = 25;
  const [editingCandidate, setEditingCandidate] = useState(null);
  const [editValues, setEditValues] = useState({ name: '', email: '', phone: '', linkedin_profile: '' });
  const [numResults, setNumResults] = useState(0); // New state to track the number of matching results
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc'); // Default to descending order

  const handleSort = (column) => {
    const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newSortOrder);

    const sortedCandidates = [...filteredCandidates].sort((a, b) => {
      if (a[column] < b[column]) return newSortOrder === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return newSortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredCandidates(sortedCandidates);
  };

  // Use useCallback to memoize fetchCandidates and avoid unnecessary re-creation
  const fetchCandidates = useCallback((page = 1, search = '') => {
    setLoading(true); // Start loading
    const searchParam = search ? `&search=${search}` : '';
    fetch(`https://r3hub-10dfb286b4df.herokuapp.com/candidates?page=${page}&limit=${itemsPerPage}${searchParam}`)
      .then(response => response.json())
      .then(data => {
        setLoading(false); // Stop loading
        if (data && Array.isArray(data.candidates)) {
          setCandidates(data.candidates);
          setFilteredCandidates(data.candidates);
          setTotalPages(data.total_pages);
          setNumResults(data.total_candidates); // Set the number of matching records
        } else {
          console.error('Error: Expected an array but received', data);
        }
      })
      .catch(error => {
        setLoading(false); // Stop loading
        console.error('Error fetching data:', error);
      });
  }, [itemsPerPage]);

  // Call fetchCandidates when currentPage or searchQuery changes
  useEffect(() => {
    fetchCandidates(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchCandidates]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setSelectedFiles(selectedFiles);
    setUploadStatus('');
  };

  const handleFileUpload = () => {
    if (selectedFiles.length === 0) {
      setUploadStatus('Please select files to upload.');
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach(file => formData.append('files', file));

    setLoading(true); // Show loading during upload
    fetch('https://r3hub-10dfb286b4df.herokuapp.com/upload_bulk', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false); // Stop loading
        setUploadStatus(data.message ? `${data.message}` : 'File upload failed.');
      })
      .catch(error => {
        setLoading(false); // Stop loading
        setUploadStatus('Error uploading files.');
        console.error('Error uploading files:', error);
      });
  };

  const handleRefresh = () => {
    window.location.reload(); // Reload the page
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);  // Reset to first page when a new search is initiated
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to format the highlights string
  const formatResumeHighlights = (highlights) => {
    if (!highlights) return 'N/A';

    // Corrected regex patterns to extract each section
    const placesWorkedRegex = /Places Worked:\s*(.*?)\s*(Skills Summary|Jobs Held|Education|$)/s;
    const skillsRegex = /Skills Summary:\s*(.*?)\s*(Jobs Held|Education|$)/s;
    const jobsRegex = /Jobs Held:\s*(.*?)\s*(Education|$)/s;
    const educationRegex = /Education:\s*(.*)/s;

    const placesWorked = (placesWorkedRegex.exec(highlights) || [])[1]?.trim().replace(/- /g, '') || 'N/A';

    // Convert the skills summary to a comma-separated list
    const skillsSummary = (skillsRegex.exec(highlights) || [])[1]
      ?.trim()
      .split(/- /)
      .filter(skill => skill.trim() !== '') // Remove any empty entries
      .join(', ') || 'N/A';

    const jobsHeld = (jobsRegex.exec(highlights) || [])[1]?.trim().replace(/- /g, '') || 'N/A';
    const education = (educationRegex.exec(highlights) || [])[1]?.trim().replace(/- /g, '') || 'N/A';

    return { placesWorked, skillsSummary, jobsHeld, education };
  };

  // Helper function to limit the candidate's name to a maximum of three words
  const formatCandidateName = (name) => {
    if (!name) return '';
    const words = name.trim().split(' ');
    return words.length > 3 ? `${words.slice(0, 3).join(' ')}...` : name;
  };


  // Component to display the formatted content with line breaks
  const DisplayFormattedContent = ({ content }) => (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );

  const handleEditClick = (candidate) => {
    setEditingCandidate(candidate.id);
    setEditValues({
      name: candidate.name,
      email: candidate.email,
      phone: candidate.phone,
      linkedin_profile: candidate.linkedin_profile
    });
  };

  const handleUpdateClick = (candidateId) => {
    fetch(`https://r3hub-10dfb286b4df.herokuapp.com/update_candidate/${candidateId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editValues)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEditingCandidate(null);
          fetchCandidates(currentPage, searchQuery); // Refresh the data
        } else {
          console.error('Error updating candidate:', data.message);
        }
      })
      .catch(error => {
        console.error('Error updating candidate:', error);
      });
  };

  // Function to generate a random hex color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Handle popup behavior
  useEffect(() => {
    if (selectedCandidate) {
      setBorderColor(getRandomColor()); // Generate random color for popup border
    }
  }, [selectedCandidate]);

  const handleNameClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  const handleDownloadClick = (candidateId) => {
    window.open(`https://r3hub-10dfb286b4df.herokuapp.com/download_resume/${candidateId}`, '_blank');
  };

  return (
    <div className="App">

      <div className="top-section">
        <div className="app-header">
          <h1 className="main-title">Resume Repo</h1>
        </div>

        <div className="search-upload-wrapper">
          <div className="search-section">
            <input
              type="text"
              placeholder='Search (e.g. "Python AND Java" OR "React")'
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
            <span className="results-count">
              &nbsp;&nbsp;&nbsp;{numResults > 0 ? `${numResults} record(s) matching your search criteria` : 'No matching records found'}
            </span>
          </div>


        </div>
      </div>

      {loading ? (
        <div className="spinner">Loading...</div>  // Loading spinner
      ) : (
        <>
          <br></br>
          <table style={{ width: '100%' }}>
            <tr>
              <td align='left'>
                <div className="button-wrapper"> {/* New wrapper for buttons */}
                  <div className="file-input-wrapper">
                    <label htmlFor="file-upload" className="custom-file-upload">
                      <i className="fas fa-file-upload"></i> &nbsp;Choose Files
                      <input id="file-upload" type="file" multiple onChange={handleFileChange} />
                    </label>
                    <span className="file-names">
                      {selectedFiles.length > 0 ? `${selectedFiles.length} file(s) selected` : 'No file chosen'}
                    </span>
                  </div>
                  <button className="upload-button" onClick={handleFileUpload} disabled={selectedFiles.length === 0}>
                    <i className="fas fa-cloud-upload-alt"></i> &nbsp; Upload
                  </button>
                  {uploadStatus && (
                    <div className="upload-status-container">
                      <p className="upload-status">{uploadStatus}</p>
                    </div>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button className="refresh-button" onClick={handleRefresh}>
                    <i className="fas fa-sync-alt"></i> &nbsp;Refresh
                  </button>
                </div>
              </td>
              <td align='right'>
                <div className="pagination">
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <span> &nbsp;&nbsp;Page {currentPage} of {totalPages} &nbsp;&nbsp;</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <table className="candidate-table">
            <thead>
              <tr>
                <th align='left'>
                  ID {sortColumn === 'id' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('name')} align='left'>
                  Name {sortColumn === 'name' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th align='left'>Email</th>
                <th align='left'>Phone</th>
                <th align='left'>
                  LinkedIn
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredCandidates.map(candidate => (
                <tr key={candidate.id}>
                  <td style={{ cursor: 'pointer', color: '#3498db' }}>
                    <span className="id-cell" onClick={() => handleDownloadClick(candidate.id)} >
                      {candidate.id} &nbsp;&nbsp;&nbsp;
                    </span>
                    <button className="edit-icon" onClick={() => handleEditClick(candidate)}>
                      <i className="fas fa-edit"></i>
                    </button>
                  </td>

                  {editingCandidate === candidate.id ? (
                    // When in edit mode, display input fields correctly aligned
                    <>
                      <td colSpan={4}>
                        <input
                          className="inline-input"
                          type="text"
                          placeholder="Name"
                          value={editValues.name}
                          onChange={(e) => setEditValues({ ...editValues, name: e.target.value })}
                        />
                        <input
                          className="inline-input"
                          type="text"
                          placeholder="Email"
                          value={editValues.email}
                          onChange={(e) => setEditValues({ ...editValues, email: e.target.value })}
                        />
                        <input
                          className="inline-input"
                          type="text"
                          placeholder="Phone"
                          value={editValues.phone}
                          onChange={(e) => setEditValues({ ...editValues, phone: e.target.value })}
                        />
                        <input
                          className="inline-input"
                          type="text"
                          placeholder="LinkedIn Profile"
                          value={editValues.linkedin_profile}
                          onChange={(e) => setEditValues({ ...editValues, linkedin_profile: e.target.value })}
                        />
                        <button className="update-button" onClick={() => handleUpdateClick(candidate.id)}>Update</button>
                        <button className="cancel-button" onClick={() => setEditingCandidate(null)}>Cancel</button>
                      </td>
                    </>
                  ) : (
                    // When not in edit mode, display the default values with the name as a clickable element
                    <>
                      <td
                        onClick={() => handleNameClick(candidate)}
                        style={{ cursor: 'pointer', color: '#3498db' }}
                      >
                        <a href="#" onClick={(e) => { e.preventDefault(); handleNameClick(candidate); }}>
                          {formatCandidateName(candidate.name)}
                        </a>
                      </td>
                      <td>{candidate.email}</td>
                      <td>{candidate.phone}</td>
                      <td>
                        {candidate.linkedin_profile && !candidate.linkedin_profile.startsWith('Unknown') ? (
                          <a
                            href={`${candidate.linkedin_profile.startsWith('http') ? '' : 'https://'}${candidate.linkedin_profile}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {candidate.linkedin_profile.replace(/\/$/, '').split('/').pop()} {/* Remove trailing slash */}
                          </a>
                        ) : 'LinkedIn Profile not found'}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          {selectedCandidate && (
            <div className="popup" style={{ borderColor: borderColor }}>
              <button className="popup-close-btn" onClick={() => setSelectedCandidate(null)}>×</button>
              <div className="popup-content">
                <h1 className="popup-candidate-name">{selectedCandidate.name}</h1>
                {(() => {
                  const formatted = formatResumeHighlights(selectedCandidate.resume_highlights);
                  return (
                    <>
                      <div>
                        <span className="popup-section-title">Places Worked:</span>
                        <DisplayFormattedContent content={formatted.placesWorked} />
                      </div>
                      <div>
                        <span className="popup-section-title"><br></br>Skills Summary:</span>
                        <DisplayFormattedContent content={formatted.skillsSummary} />
                      </div>
                      <div>
                        <span className="popup-section-title"><br></br>Jobs Held:</span>
                        <DisplayFormattedContent content={formatted.jobsHeld} />
                      </div>
                      <div>
                        <span className="popup-section-title"><br></br>Education:</span>
                        <DisplayFormattedContent content={formatted.education} />
                      </div>

                    </>
                  );
                })()}
              </div>
            </div>
          )}

          <br></br>
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span> &nbsp;&nbsp;Page {currentPage} of {totalPages} &nbsp;&nbsp;</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ResRepo;

import React from 'react';
import './UnderConstruction.css'; // Optional: Create a CSS file for styling if needed

function UnderConstruction() {
  return (
    <div className="under-construction">
      <h2>Page Under Construction</h2>
      <p>We're working on this feature. Stay tuned!</p>
    </div>
  );
}

export default UnderConstruction;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file for navbar styling

function Navbar() {
  return (
    <nav className="navbar">
      <ul className="navbar-menu">
        <li className="navbar-item">
          <NavLink to="/home" className="navbar-link" activeClassName="active">Home</NavLink>
        </li>
        <li className="navbar-item">
          <NavLink to="/resrepo" className="navbar-link" activeClassName="active">ResRepo</NavLink>
        </li>
        <li className="navbar-item">
          <NavLink to="/docrepo" className="navbar-link" activeClassName="active">DocRepo</NavLink>
        </li>
        <li className="navbar-item">
          <NavLink to="/ats" className="navbar-link" activeClassName="active">ATS</NavLink>
        </li>
        <li className="navbar-item">
          <NavLink to="/blog" className="navbar-link" activeClassName="active">Blog</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
